import React, { useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
  MdCloudDownload,
  MdFormatListBulleted as MdFormatListBulletedIcon,
  MdDelete as DeleteIcon,
  MdEdit as EditIcon,
  MdFileUpload as UploadIcon,
  MdNoteAdd as ListIcon,
  MdSkipNext as StepIcon
} from 'react-icons/md'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router-dom'

import {
  Container,
  Grid,
  Page,
  OverPage,
  Paper,
  Box,
  Typography,
  Divider,
  Status,
  MenuItem,
  Select,
  InputLabel,
  Button,
  CommentBox,
  Table
} from 'components'

import { useTheme, Button as MuiButton } from '@material-ui/core'
import FileSaver from 'file-saver'
import { decode } from 'html-entities'
import { DateTime } from 'luxon'
import { Column } from 'material-table'
import { useConfirm } from 'material-ui-confirm'
import * as yup from 'yup'

import { ReactComponent as PageNotFoundSvg } from 'assets/svg/page_not_found.svg'
import complianceOwners from 'commons/constants/complainceOwners'
import useAttachmentStatus from 'hooks/attachment'
import useCheckoutStatus from 'hooks/checkoutStatus'
import useStatus from 'hooks/status'
import {
  getComplaintById,
  updateComplaintById,
  storeCommentComplaintById,
  getTrackingComplaintById,
  getAttachmentById,
  deleteTrackingComplaintById,
  closeComplaintById, deleteAttachmentById
} from 'services/jarvisApi/complaint'
import yupValidationResolver from 'utils/yupValidationResolver'

import ComplaintCloseDialog from './components/CloseComplaintDialog'
import ComplaintCheckoutStatusDialog from './components/ComplaintCheckoutDialog'
import ComplaintOwnerDialog from './components/ComplaintOwnerDialog'
import ComplaintReclassifiedDialog from './components/ComplaintReclassifiedDialog'
import ComplaintWaitInformationDialog from './components/ComplaintWaitInformationDialog'
import CreateStepDialog from './components/CreateStepDialog'
import FinalReportDialog from './components/FinalReportDialog'
import PrintButton from './components/PrintButton'
import TrackingDialog from './components/TrackingDialog'

interface IParams{
  params: {
    id: string;
  }
}

interface IAttachment {
  complaint_id: number
  created_at: string
  id: number
  location: string
  name: string
  size: string
  status: string
  updated_at: string
  is_final_report: boolean
  origin: 'USER' | 'ADMIN'
}

interface IAnswer {
  id: number
  complaint_id: number
  answer: string
  created_at: string
  question: string
  updated_at: string
}

interface ITrackingData {
  id: number
  complaint_id: number
  tracking: string
  user_id: number
  created_at: string
  updated_at: string
  user: {
    id: number
    email: string
  }
}

const Show: React.FC = () => {
  const { params }: IParams = useRouteMatch()
  const statusHook = useStatus()
  const checkoutStatusHook = useCheckoutStatus()
  const statusAttachmentHook = useAttachmentStatus()
  const [isSending, setIsSending] = useState(false)
  // dialogs
  const [waitForInformationDialogOpen, setWaitForInformationDialogOpen] = useState(false)
  const [dialogTrackingOpen, setDialogTrackingOpen] = useState(false)
  const [dialogFinalReportOpen, setDialogFinalReportOpen] = useState(false)
  const [dialogTrackingData, setDialogTrackingData] = useState({} as any)
  const [dialogComplaintCloseOpen, setDialogComplaintCloseOpen] = useState(false)
  const [dialogComplaintOwnerOpen, setDialogComplaintOwnerOpen] = useState(false)
  const [dialogComplaintReclassifiedOpen, setDialogComplaintReclassifiedOpen] = useState(false)
  const [dialogComplaintCheckoutOpen, setDialogComplaintCheckoutOpen] = useState(false)
  const [dialogCreateNewStepOpen, setDialogCreateNewStepOpen] = useState(false)
  // states
  const [dialogComplaintData, setDialogComplaintData] = useState({} as any)
  const [dialogFinalReportData, setDialogFinalReportData] = useState({} as any)
  const [dialogCheckoutStatusData, setDialogCheckoutStatusData] = useState({ checkoutStatus: '', complaintId: '', checkoutMessage: '' })
  const [dialogCreateNewStepData, setDialogCreateNewStepData] = useState({} as any)
  // hooks
  const clientTableRef = useRef({ onQueryChange: () => {} })
  const confirm = useConfirm()
  const theme = useTheme()

  const columns: Column<ITrackingData>[] = [
    { title: 'Tracking', render: (row) => decode(row.tracking), field: 'tracking', cellStyle: { maxWidth: 50 } },
    { title: 'E-mail', render: (row) => row.user.email },
    { title: 'Data de Criação', render: (row) => DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy') }
  ]

  const { data, status, refetch, error } = useQuery(`complaint_${params.id}`,
    () => getComplaintById(params.id).then(({ data }) => data),
    { refetchOnWindowFocus: false, retry: false }
  )

  const validationSchema = yup.object().shape({
    message: yup.string().required('Campo obrigatório')
  })

  const validationComplaintCloseSchema = yup.object().shape({
    feedback: yup.string()
  })

  const methodsCommentBox = useForm({
    resolver: yupValidationResolver(validationSchema)
  })

  const methodsComplaintClose = useForm({
    resolver: yupValidationResolver(validationComplaintCloseSchema)
  })

  const handleDialogTrackingClose = () => {
    setDialogTrackingOpen(false)
  }

  const handleDialogTrackingOpen = (data = {}) => {
    setDialogTrackingData({ ...data, complaintId: params.id })
    setDialogTrackingOpen(true)
  }

  const handleDialogFinalReportOpen = () => {
    setDialogFinalReportData({ complaintId: params.id })
    setDialogFinalReportOpen(true)
  }

  const handleDialogCreateNewStepOpen = () => {
    setDialogCreateNewStepData({ complaintId: params.id, status: statusHook[data.status].text, step: data.step })
    setDialogCreateNewStepOpen(true)
  }

  const onSuccess = async () => {
    await refetch()
    return clientTableRef.current.onQueryChange()
  }

  const handleDeleteTrackingById = async (trackingId: string) => {
    await confirm({
      title: 'Deseja realizar essa alteração?',
      confirmationText: 'Ok',
      cancellationText: 'Cancelar'
    })
    await deleteTrackingComplaintById(params.id, trackingId)
    await refetch()
    return clientTableRef.current.onQueryChange()
  }

  const handleDialogComplaintCloseDialogOpen = (data = {}) => {
    setDialogComplaintData(data)
    setDialogComplaintCloseOpen(true)
  }

  const handleDialogComplaintClose = () => {
    setDialogComplaintCloseOpen(false)
  }

  const onSubmitComplaintClose = (formValues: object) => {
    setIsSending(true)

    return closeComplaintById(params.id, formValues)
      .then(() => handleDialogComplaintClose())
      .then(() => refetch())
      .then(() => clientTableRef.current.onQueryChange())
      .then(() => methodsComplaintClose.reset())
      .finally(() => setIsSending(false))
  }

  const handleWaitForInformationDialogOpen = () => {
    setWaitForInformationDialogOpen(true)
  }

  const handleWaitForInformationDialogClose = () => {
    setWaitForInformationDialogOpen(false)
  }

  const handleStatusChange = async (status : string) => {
    if (status === 'WAITING_FOR_MORE_INFORMATION') {
      return handleWaitForInformationDialogOpen()
    } else if (status === 'DONE') {
      return handleDialogComplaintCloseDialogOpen({ feedback: data.feedback })
    }

    await confirm({
      title: 'Deseja realizar essa alteração?',
      confirmationText: 'Ok',
      cancellationText: 'Cancelar'
    })

    await updateComplaintById(params.id, { status })
    await refetch()
  }

  const handleDialogCheckoutStatusOpen = (checkoutStatus: string, checkoutMessage: string = '') => {
    setDialogCheckoutStatusData({ checkoutStatus, complaintId: params.id, checkoutMessage })
    return setDialogComplaintCheckoutOpen(true)
  }
  const handleCheckoutStatusChange = async (checkoutStatus: string, checkoutMessage: string) => {
    if (checkoutStatusHook[checkoutStatus].text === checkoutStatusHook.INCONCLUSIVE.text) {
      return handleDialogCheckoutStatusOpen(checkoutStatus, checkoutMessage)
    }
    await confirm({
      title: 'Deseja realizar essa alteração?',
      confirmationText: 'Ok',
      cancellationText: 'Cancelar'
    })
    await updateComplaintById(params.id, { checkout_status: checkoutStatus })
    return await refetch()
  }

  const handleDialogCheckoutStatusClose = () => {
    setDialogComplaintCheckoutOpen(false)
  }

  const handleDialogOwnerChange = () => {
    setDialogComplaintOwnerOpen(true)
  }

  const handleDialogOwnerChangeClose = () => {
    setDialogComplaintOwnerOpen(false)
  }

  const handleDialogFinalReportClose = () => {
    setDialogFinalReportOpen(false)
  }

  const handleCreateNewStepClose = () => {
    setDialogCreateNewStepOpen(false)
  }

  const handleDialogReclassifiedChange = () => {
    setDialogComplaintReclassifiedOpen(true)
  }

  const handleDialogReclassifiedChangeClose = () => {
    setDialogComplaintReclassifiedOpen(false)
  }

  const handleOwnerChange = (owner : string) => {
    if (owner.toLocaleLowerCase() === complianceOwners.Outro.toLocaleLowerCase()) {
      return handleDialogOwnerChange()
    }

    return confirm({
      title: 'Deseja realizar essa alteração?',
      confirmationText: 'Ok',
      cancellationText: 'Cancelar'
    }).then(() => updateComplaintById(params.id, { owner })
      .then(() => refetch())
    )
  }

  const handleUpdateComplaint = async (formValues: any) => {
    await updateComplaintById(params.id, { owner: formValues.owner, topic_reclassified_id: formValues.topic_reclassified_id })
    return onSuccess()
  }

  const handleDownloadAttachment = async (id: number, attachmentName: string) => {
    const { data } = await getAttachmentById(params.id, id)
    return FileSaver.saveAs(data.signedUrl, attachmentName)
  }

  const handleDeleteAttachment = async (id: number) => {
    await confirm({
      title: 'Deseja realizar essa exclusão?',
      confirmationText: 'Sim',
      cancellationText: 'Não'
    })
    await deleteAttachmentById(params.id, id)
    await refetch()
  }

  const onSubmitCommentBox = (formValues: object) => {
    setIsSending(true)
    return storeCommentComplaintById(params.id, formValues)
      .then(() => onSuccess())
      .then(() => methodsCommentBox.reset())
      .finally(() => setIsSending(false))
  }

  const editBtn = <MdFormatListBulletedIcon size={20} color={theme.palette.primary.main}/>
  const deleteIcon = <DeleteIcon size={20} color={theme.palette.primary.main}/>

  return (
    <OverPage>
      <Page title={`Denúncia #${params.id}` } backTo>
        <Container justifyContent="center">
          {error && (
            <PageNotFoundSvg />
          )}
          {status === 'success' && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper>
                  <Box display="flex">
                    <Box component="div" flexGrow={1} p={2}>
                      <Typography variant="h6" display="inline">Informações iniciais</Typography>
                    </Box>
                    <Box component="div" p={2} paddingBottom={1} paddingTop={1}>
                      <Status backgroundColor={statusHook[data.status].color}>
                        <Typography variant="body2" display="inline">
                          {statusHook[data.status].text}
                        </Typography>
                      </Status>
                    </Box>
                    <Box component="div" p={1}>
                      <PrintButton complaintId={params.id} />
                    </Box>
                  </Box>
                  <Divider />
                  <Box component="div" display="block" p={2}>
                    <Grid container>
                      <Grid item sm={6} xs={12}>
                        <Typography>Nome: {data.name || '-'}</Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Typography>Telefone: {data.phone || '-'}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box component="div" display="block" p={2}>
                    <Grid container>
                      <Grid item sm={6} xs={12}>
                        <Typography>E-mail: {data.mail || '-'}</Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Typography>Data Criação: {DateTime.fromISO(data.created_at).toFormat('dd/MM/yyyy HH:mm')} {data.status === 'DONE' && data.closed_at && `/ Fechado em: ${DateTime.fromISO(data.closed_at).toFormat('dd/MM/yyyy HH:mm')}`}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box component="div" display="block" p={2}>
                    <Grid container>
                      <Grid item sm={6} xs={12}>
                        <Typography>Termo: {data.term_accept ? 'Aceito' : 'Não Aceito' }</Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Typography>Chave de acesso: {data.access_key}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box component="div" display="block" p={2}>
                    <Grid container>
                      <Grid item sm={6} xs={12}>
                        <Typography>Tema: {data.topic_reclassified?.group || data.topic.group} <Button color='primary' variant='text' endIcon={<EditIcon />} onClick={() => handleDialogReclassifiedChange()}>Reclassificar</Button></Typography>
                      </Grid>
                      {data.topic_reclassified?.group && (
                        <Grid item sm={6} xs={12}>
                          <Typography>Tema original: {data.topic.group}</Typography>
                        </Grid>)}
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              {data.checkout_status === 'INCONCLUSIVE' && data.checkout_message && <Grid item xs={12}>
                <Paper>
                  <Box display="flex">
                    <Box component="div" flexGrow={1} p={2}>
                      <Typography variant="h6" display="inline">Denúncia inconclusiva</Typography>
                      <Button color='primary' variant='text' endIcon={<EditIcon />} onClick={() => handleDialogCheckoutStatusOpen(data.checkout_status, data.checkout_message)}>Editar</Button>
                    </Box>
                  </Box>
                  <Divider />
                  <Box component="div" display="block" p={2} whiteSpace={'pre-wrap'}>
                    <Typography>{data.checkout_message}</Typography>
                  </Box>
                </Paper>
              </Grid>}
              <Grid item xs={12} md={3}>
                <Paper>
                  <Box display="flex">
                    <Box component="div" flexGrow={1} p={2}>
                      <Typography variant="h6" display="inline">Opções</Typography>
                    </Box>
                    <Box component="div" p={2} paddingBottom={1} paddingTop={2}>
                      <Button color='primary' variant='text' endIcon={<StepIcon />} onClick={() => handleDialogCreateNewStepOpen()}>Visualizar etapa</Button>
                    </Box>
                  </Box>
                  <Divider />
                  <Box component="div" flexGrow={1} p={2}>
                    <InputLabel id="select-new-status">Status</InputLabel>
                    <Select
                      labelId="select-new-status"
                      id="select-new-status"
                      value={data.status}
                      onChange={(e) => handleStatusChange(`${e.target.value}`)}
                      disabled={(status !== 'success')}
                      fullWidth
                      variant="outlined"
                    >
                      {Object.keys(statusHook)
                        .filter((status) => statusHook[status].text !== statusHook.NEW.text)
                        .map((status) => (
                          <MenuItem key={status} value={status}>{statusHook[status].text}</MenuItem>
                        ))}
                    </Select>
                  </Box>
                  <Box component="div" flexGrow={1} p={2}>
                    <InputLabel id="select-new-checkout-status">Procedência da denúncia</InputLabel>
                    <Select
                      labelId="select-new-checkout-status"
                      id="select-new-checkout-status"
                      value={data.checkout_status || 'NOT_CLASSIFIED'}
                      variant="outlined"
                      onChange={(e) => handleCheckoutStatusChange(`${e.target.value}`, data.checkout_message)}
                      fullWidth
                    >
                      {Object.keys(checkoutStatusHook).map((status) => {
                        const text = checkoutStatusHook[status].text
                        return (
                          <MenuItem key={status} value={status}>{text}</MenuItem>
                        )
                      })}
                    </Select>
                  </Box>
                  <Box component="div" flexGrow={1} p={2}>
                    <InputLabel id="select-new-owner">Responsável</InputLabel>
                    <Select
                      labelId="select-new-owner"
                      id="select-new-owner"
                      value={data.owner}
                      variant="outlined"
                      onChange={(e) => handleOwnerChange(`${e.target.value}`)}
                      fullWidth
                    >
                      <MenuItem value={data.owner}>{data.owner}</MenuItem>
                      {Object.values(complianceOwners).filter((value) => value !== data.owner).map((value, idx) => {
                        return <MenuItem key={idx} value={value}>{value}</MenuItem>
                      })}
                    </Select>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={9}>
                <Paper>
                  <Box display="flex">
                    <Box component="div" flexGrow={1} p={2}>
                      <Typography variant="h6" display="inline">Anexos</Typography>
                    </Box>
                    <Box component="div" p={2} paddingBottom={1} paddingTop={1}>
                      <Button color='primary' variant='text' endIcon={<UploadIcon />} onClick={() => handleDialogFinalReportOpen()}>Enviar relatório final</Button>
                    </Box>
                  </Box>
                  <Box component="div" p={2} justifyContent="center">
                    {data.attachments.length === 0 && <Typography variant="body2">Sem anexos para exibir</Typography>}
                    {data.attachments.length > 0 && (
                      <Grid container spacing={2}>
                        {data.attachments.map((attachment: IAttachment) => (
                          <Grid key={attachment.id} xs={12} sm={6} md={3} item>
                            <Paper variant="outlined">
                              <Box p={2} component="div">
                                <Typography noWrap display="block" variant="body2">{attachment.name}</Typography>
                                <Typography noWrap display="block" variant="caption">{attachment.size} bytes</Typography>
                                {attachment.is_final_report && (
                                  <Box color={theme.palette.info.dark}>
                                    <Typography noWrap display="block" variant="caption">Relatório Final</Typography>
                                  </Box>
                                )}
                                <Box display="inline-flex" width='100%'>
                                  <Box color={statusAttachmentHook[attachment.status].color} width={1}>
                                    <Typography noWrap display="block" variant="caption">{statusAttachmentHook[attachment.status].text}</Typography>
                                    {attachment.status === 'DONE' && (
                                      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={1}>
                                        <Box color={statusAttachmentHook[attachment.status].color} width='50%'>
                                          <MuiButton color='inherit' size="small" endIcon={<MdCloudDownload />} onClick={() => attachment.status === 'DONE' && handleDownloadAttachment(attachment.id, attachment.name)}>Baixar</MuiButton>
                                        </Box>
                                        {attachment.origin === 'ADMIN' && (
                                          <Box width='50%' color={statusAttachmentHook.FAILED.color}>
                                            <MuiButton color='inherit' size="small" endIcon={<DeleteIcon />} onClick={() => attachment.status === 'DONE' && handleDeleteAttachment(attachment.id)}>Excluir</MuiButton>
                                          </Box>
                                        )}
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            </Paper>
                          </Grid>
                        ))}

                      </Grid>)}
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  <Grid container>
                    <Grid item xs={6}>
                      <Box component="div" p={2} mt={2} display="flex" justifyContent="flex-start">
                        <Typography variant="h6">Trackings</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box component="div" p={2} mt={2} display="flex" justifyContent="flex-end">
                        <Button color="primary" variant='text' onClick={() => handleDialogTrackingOpen()} endIcon={<ListIcon />}>
                          Novo tracking
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box component="div" p={2}>
                    <Table
                      elevation={0}
                      tableRef={clientTableRef}
                      data={(query) => new Promise((resolve, reject) => {
                        getTrackingComplaintById(params.id, query.page + 1, query.pageSize)
                          .then(({ data }) => resolve({
                            data: data.data,
                            page: data.meta.current_page - 1,
                            totalCount: data.meta.total
                          }))
                          .catch(reject)
                      })}
                      columns={columns}
                      actions={
                        [
                          {
                            icon: () => editBtn,
                            tooltip: 'Abrir',
                            onClick: (e, rowData) => handleDialogTrackingOpen(rowData)
                          },
                          {
                            icon: () => deleteIcon,
                            tooltip: 'Delete',
                            onClick: (e, rowData) => handleDeleteTrackingById(rowData.id)
                          }
                        ]}
                      options={{ actionsColumnIndex: -1, pageSize: 5 }}
                    />
                  </Box>
                </Paper>
              </Grid>
              {data.comments.length > 0 && (
                <Grid item xs={12}>
                  <Paper>
                    <Box component="div" p={2}>
                      <Typography variant="h6" display="inline">Comentários</Typography>
                    </Box>
                    <Box component="div" p={2}>
                      <CommentBox
                        methods={methodsCommentBox}
                        onSubmit={onSubmitCommentBox}
                        data={data.comments}
                      />
                    </Box>
                  </Paper>
                </Grid>)}
              {data.answers.map((answer: IAnswer) => (
                <Grid key={answer.id} item xs={12}>
                  <Paper>
                    <Box component="div" p={2}>
                      <Typography variant="h6" display="inline">{answer.question}</Typography>
                    </Box>
                    <Box component="div" p={2}>
                      {decode(`${answer.answer}`)}
                    </Box>
                  </Paper>
                </Grid>))}
            </Grid>)}
        </Container>
      </Page>
      <ComplaintWaitInformationDialog
        openDialog={waitForInformationDialogOpen}
        isSending={isSending}
        setIsSending={setIsSending}
        handleDialogClose={handleWaitForInformationDialogClose}
        onSuccess={onSuccess}
        complaintId={params.id}
      />
      <TrackingDialog
        openDialog={dialogTrackingOpen}
        isSending={isSending}
        setIsSending={setIsSending}
        handleDialogClose={handleDialogTrackingClose}
        onSuccess={onSuccess}
        data={dialogTrackingData}
      />
      <ComplaintCloseDialog
        openDialog={dialogComplaintCloseOpen}
        isSending={isSending}
        handleDialogClose={handleDialogComplaintClose}
        onSubmit={onSubmitComplaintClose}
        methods={methodsComplaintClose}
        data={dialogComplaintData}
      />
      <ComplaintOwnerDialog
        openDialog={dialogComplaintOwnerOpen}
        isSending={isSending}
        handleDialogClose={handleDialogOwnerChangeClose}
        onSuccess={handleUpdateComplaint}
      />
      <ComplaintReclassifiedDialog
        openDialog={dialogComplaintReclassifiedOpen}
        isSending={isSending}
        handleDialogClose={handleDialogReclassifiedChangeClose}
        onSuccess={handleUpdateComplaint}
      />
      <FinalReportDialog
        onSuccess={onSuccess}
        openDialog={dialogFinalReportOpen}
        handleDialogClose={handleDialogFinalReportClose}
        setIsSending={setIsSending}
        isSending={isSending}
        data={dialogFinalReportData}
      />
      <ComplaintCheckoutStatusDialog
        onSuccess={onSuccess}
        openDialog={dialogComplaintCheckoutOpen}
        handleDialogClose={handleDialogCheckoutStatusClose}
        setIsSending={setIsSending}
        isSending={isSending}
        checkoutData={dialogCheckoutStatusData}
      />
      <CreateStepDialog
        onSuccess={onSuccess}
        openDialog={dialogCreateNewStepOpen}
        handleDialogClose={handleCreateNewStepClose}
        setIsSending={setIsSending}
        isSending={isSending}
        data={dialogCreateNewStepData}
      />
    </OverPage>
  )
}

export default Show
