import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  MdClose as CloseIcon,
  MdEdit as EditIcon,
  MdDelete as DeleteIcon
} from 'react-icons/md'

import { Box, Button, Divider, Grid, Typography } from 'components'
import {
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from 'components/DialogComponents'
import { Input, Form, Textarea } from 'components/HookForm'

import { IconButton } from '@material-ui/core'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import useStatus from 'hooks/status'
import { updateComplaintById, updateStep, deleteStep, getComplaintById } from 'services/jarvisApi/complaint'
import yupValidationResolver from 'utils/yupValidationResolver'

interface ITrackingProps {
  onSuccess(): Promise<void>
  openDialog: boolean
  handleDialogClose(): any
  setIsSending: React.Dispatch<React.SetStateAction<boolean>>
  isSending: boolean
  data?: Record<string, any>
}

const CreateNewStepDialog : React.FC<ITrackingProps> = ({ onSuccess, openDialog, handleDialogClose, isSending, data = {}, setIsSending }) => {
  const [steps, setSteps] = useState([])
  const [stepId, setStepId] = useState<number | null>(null)
  const statusHook = useStatus()

  const validationSchema = yup.object().shape({
    step: yup.string().required('Campo obrigatório'),
    step_description: yup.string()
  })

  const methods = useForm({
    resolver: yupValidationResolver(validationSchema)
  })

  const refreshData = async () => {
    const complaint = await getComplaintById(data.complaintId)
    if (complaint.data?.step) {
      setSteps(complaint.data?.step)
    }
  }

  useEffect(() => {
    refreshData()
    methods.reset({})
  }, [openDialog])

  const onSubmitStepEdit = (idStep: number, formValues: object) => {
    setIsSending(true)
    return updateStep(data.complaintId, idStep, { ...formValues, step_internal: false })
      .then(() => onSuccess())
      .then(() => refreshData())
      .then(() => methods.reset({}))
      .then(() => setStepId(null))
      .finally(() => setIsSending(false))
  }

  const onSubmit = (formValues: any) => {
    setIsSending(true)

    if (stepId) {
      console.log('stepId', stepId)
      return onSubmitStepEdit(stepId, formValues)
    }

    return updateComplaintById(data.complaintId, { step: formValues.step, step_description: formValues.description, step_internal: false })
      .then(() => onSuccess())
      .then(() => refreshData())
      .then(() => methods.reset({}))
      .finally(() => setIsSending(false))
  }

  const handleEditStep = (id: number) => {
    setStepId(id)
    const currentStep = steps.find((step: any) => step.id === id)
    if (currentStep) {
      methods.reset(currentStep)
    }
  }

  const handleDeleteStep = (idStep: number) => {
    setIsSending(true)

    return deleteStep(data.complaintId, idStep)
      .then(() => onSuccess())
      .then(() => refreshData())
      .finally(() => setIsSending(false))
  }

  const onClose = () => {
    setStepId(null)
    methods.reset()
    handleDialogClose()
  }

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="md"
      open={openDialog}
      onClose={onClose}
      aria-labelledby="form-dialog-create-step-add"
      style={{ minHeight: 'calc(100% + 24px)', maxHeight: 'none' }}
    >
      <DialogContent>
        <Form onSubmit={onSubmit} methods={methods}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={11}>
              <DialogTitle id="form-dialog-title" style={{ paddingLeft: 0 }}>
                {!stepId ? 'Nova etapa' : `Editar etapa (#${stepId})`}
                <IconButton
                  aria-label="close"
                  style={{
                    position: 'absolute',
                    right: '8px',
                    top: '8px',
                    color: '#9e9e9e'
                  }}
                  onClick={onClose}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Box paddingLeft={0} paddingTop={0}>
                <DialogContentText>
                  {!stepId ? 'Crie uma nova' : 'Edite'} etapa visível para o
                  denunciante nesta denúncia com o status atual ({data.status}).
                </DialogContentText>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Input name="step" label="Etapa" fullWidth />
            </Grid>
            <Grid item xs={11}>
              <Textarea
                name="description"
                label="Descreva a etapa atual (opcional)"
                fullWidth
                minRows={3}
              />
            </Grid>
            <Grid item xs={11}>
              <Box minHeight={25}>
                <DialogActions style={{ justifyContent: 'left' }}>
                  <Button
                    onClick={onClose}
                    color="primary"
                    disabled={isSending}
                  >
                    Cancelar
                  </Button>
                  <Button type="submit" color="primary" disabled={isSending}>
                    {!stepId ? 'Cadastrar' : 'Editar'}
                  </Button>
                </DialogActions>
              </Box>
            </Grid>
          </Grid>
        </Form>
        {Boolean(steps.length) && (
          <Box display="flex" flexDirection="column">
            <Box p={3}>
              <Typography variant="h6">Etapas cadastradas</Typography>
              <Divider />
              {steps.map((stepState: any, key) => (
                <div key={`step-${key}`}>
                  <Box
                    key={`step_${stepState.id}`}
                    mt={1}
                    mb={1}
                    display="flex"
                    flexDirection="column"
                  >
                    <>
                      <Typography variant="subtitle1">
                        Etapa #{(key - steps.length) * -1}: {stepState.step}
                      </Typography>
                      <Box position="absolute" right="24px">
                        <IconButton
                          aria-label="close"
                          onClick={() => handleEditStep(stepState.id)}
                          style={{
                            color: '#9e9e9e'
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="close"
                          onClick={() => handleDeleteStep(stepState.id)}
                          style={{
                            color: '#9e9e9e'
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </>
                    {stepState.description && (
                      <Box width="85%">
                        <Typography variant="body2">
                          Descrição: {stepState.description}
                        </Typography>
                      </Box>
                    )}
                    <Box width="85%" mt={1} display="flex" flexDirection="column">
                      <Typography variant="caption">
                        ID: #{stepState.id}
                      </Typography>
                      <Typography variant="caption">
                        Status: {statusHook[stepState.complaint_status].text}
                      </Typography>
                      <Typography variant="caption">
                        Atualizado em:{' '}
                        {DateTime.fromISO(stepState.created_at).toFormat(
                          'dd/MM/yyyy HH:mm'
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                </div>
              ))}
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CreateNewStepDialog
